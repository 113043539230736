import { API } from 'utils/api';
import { create } from 'zustand';
import { useAppointmentsStore } from '../AppointmentsBlock/AppointmentsStore';
import { useClientsStore } from 'Pages/Clients/ClientsStore';

export let useAddGroupMembersStore = create((set, get) => ({
  state: 'button',
  clients: [],
  clientsOptions: [],
  clientSearchInputValue: undefined,
  changeState: (state) => {
    set({ state });
  },

  getSpecialistClients: async () => {
    const { groupMembers } = useAppointmentsStore.getState();

    const data = await API.Client.getAll();
    const clientsOptions = [
      { value: 'addNew', label: '+ Додати нового', color: '#FF8A35' },
      ...data
        .filter(({ phone }) =>
          !groupMembers.some(({ phone: memberPhone }) => memberPhone === phone)
        )
        .map(({ _id, name, surname, phone }) => ({
          value: _id,
          label: `${name} ${surname}`,
          phone,
        })),
    ];

    set({ clients: data, clientsOptions });
  },

  clientSearchInputHandler: (data) => {
    set({
      clientSearchInputValue: {
        value: undefined,
        label: 'Оберіть клієнта або створіть нового ',
        color: '#F4F5F7',
      },
    });

    if (data.value == 'addNew') {
      set({ state: 'button' });

      useAppointmentsStore.getState().changeGroupAppointmentWindowState(false);
      useClientsStore.getState().showAddContactForm();

      return;
    }

    const [newMember] = get().clients.filter((client) => {
      return client._id == data.value;
    });

    const groupMembers = useAppointmentsStore.getState().groupMembers;
    useAppointmentsStore.setState({
      groupMembers: [...groupMembers, newMember],
    });

    const clientsOptions = get().clientsOptions.filter(({ phone }) => newMember.phone !== phone)

    set({ state: 'button', clientsOptions });

  },
}));
