import React, { useState } from 'react';
import Images from '../../Images';
import { Button } from '../LandingComponents';
import styles from './Header.module.css';
import stylesM from './HeaderM.module.css';
import menuM from '../../Images/menuM.png';
import ua from '../../Images/ukraine.png';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'i18n/utils/LanguageSwitcher';

export function Menu(props) {
  const { t } = useTranslation();
  if (props.show) {
    if (props.type == 'menu2') {
      return (
        <>
          <div
            id={'menu'}
            style={{ height: '140px' }}
            className={stylesM.menu_container}
          >
            {/* <div id={'menu'} className={stylesM.menu_link}>
            <a href={'#functions'}  className={stylesM.title}>Функції</a>
        </div>
        
        <div id={'menu'} className={stylesM.menu_link}>
            <a href={'#howitworks'}  className={stylesM.title}>Як це працює?</a>
        </div>
        
        <div id={'menu'} style={{}} className={stylesM.menu_link}>
            <a href={'#price'}  className={stylesM.title}>Ціна</a>
        </div> */}
            <div id={'menu'} style={{}} className={stylesM.menu_link}>
              <a
                href={'#'}
                style={{ fontWeight: 'bold' }}
                className={stylesM.title}
                onClick={() => {
                  window.location.href = '/login';
                }}
              >
                🔑 {t('log_in')}
              </a>
            </div>
            <div
              id={'menu'}
              style={{ borderBottom: 'none' }}
              className={stylesM.menu_link}
              onClick={() => {
                window.location.href = '/signup';
              }}
            >
              <a
                href={'#'}
                style={{ fontWeight: 'bold' }}
                className={stylesM.title}
              >
                💻 {t('registration')}
              </a>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div id={'menu'} className={stylesM.menu_container}>
          <div id={'menu'} className={stylesM.menu_link}>
            <a href={'#functions'} className={stylesM.title}>
              {t('about_service')}
            </a>
          </div>

          <div id={'menu'} className={stylesM.menu_link}>
            <a href={'#howitworks'} className={stylesM.title}>
              {t('how_it_works')}
            </a>
          </div>

          <div id={'menu'} style={{}} className={stylesM.menu_link}>
            <a href={'#price'} className={stylesM.title}>
              {t('price')}
            </a>
          </div>
          <div id={'menu'} style={{}} className={stylesM.menu_link}>
            <a
              href={'#'}
              style={{ fontWeight: 'bold' }}
              className={stylesM.title}
              onClick={() => {
                window.location.href = '/login';
              }}
            >
              🔑 {t('log_in')}
            </a>
          </div>
          <div
            id={'menu'}
            style={{ borderBottom: 'none' }}
            className={stylesM.menu_link}
            onClick={() => {
              window.location.href = '/signup';
            }}
          >
            <a
              href={'#'}
              style={{ fontWeight: 'bold' }}
              className={stylesM.title}
            >
              💻 {t('registration')}
            </a>
          </div>
        </div>
      </>
    );
  }
}

function Header(props) {
  const { t } = useTranslation();
  let [menu, setMenu] = useState(false);

  //Відкрити/закрити меню
  function menuHandler() {
    menu ? setMenu(false) : setMenu(true);
  }

  //Закриття меню
  function handler(e) {
    if (menu == true && e.target.id != 'menu') {
      setMenu(false);
    }
  }

  document.body.addEventListener('click', handler);

  if (props.type == 'mobile') {
    return (
      <>
        <div className={stylesM.container}>
          <div className={stylesM.logo_container}>
            <Images img={'logoM'} />
            <img className={stylesM.ua_flag} src={ua} alt="" />
          </div>

          <div className={styles.rightBlock}>
            <LanguageSwitcher />

            <img
              id={'menu'}
              onClick={menuHandler}
              src={menuM}
              alt=""
              width={'30px'}
              height={'20px'}
            />
            <Menu show={menu} />
          </div>
        </div>
      </>
    );
  }

  if (props.type == 'desktop') {
    return (
      <>
        <div className={styles.header_container_desktop}>
          <div className={styles.logo_container}>
            <Images planguinLogoWidth={'250px'} img={'logo'} />
            <div className={styles.logo_title}>{t('made_in_ukraine')}</div>
          </div>

          <div className={styles.links}>
            <a href={'#functions'} className={styles.link}>
              {` ${t('about_service')}`}
            </a>
            <a href={'#howitworks'} className={styles.link}>
              {` ${t('how_it_works')}`}
            </a>
            <a href={'#price'} className={styles.link}>
              {` ${t('price')}`}
            </a>
          </div>

          <div className={styles.rightBlock}>
            <LanguageSwitcher />
            <Button
              type={4}
              name={t('log_in')}
              onClick={() => {
                window.location.href = '/login';
              }}
            />
          </div>
        </div>
      </>
    );
  }

  if (props.type == 'desktop2') {
    return (
      <>
        <div
          style={{ width: '100%', paddingLeft: '30px', paddingRight: '30px' }}
          className={styles.header_container_desktop}
        >
          <div className={styles.logo_container}>
            <Images
              planguinLogoWidth={'250px'}
              img={'logo'}
              onClick={() => {
                window.location.href = '/';
              }}
            />
            <div className={styles.logo_title}>{t('made_in_ukraine')}</div>
          </div>
          <div className={styles.links}>
            {/* <a href={'/#'} className={styles.link}> функції</a>
            <a href={'#howitworks'} className={styles.link}> як це працює?</a>
            <a href={'#price'} className={styles.link}> ціна</a> */}
          </div>

          <Button
            type={4}
            name={t('log_in')}
            onClick={() => {
              window.location.href = '/login';
            }}
          />
        </div>
      </>
    );
  }

  if (props.type == 'mobile2') {
    return (
      <>
        <div
          style={{ width: '100%', paddingLeft: '30px', paddingRight: '30px' }}
          className={stylesM.container}
        >
          <div
            className={stylesM.logo_container}
            onClick={() => {
              window.location.href = '/';
            }}
          >
            <Images img={'logoM'} />
            <img className={stylesM.ua_flag} src={ua} alt="" />
          </div>

          <img
            id={'menu'}
            onClick={menuHandler}
            src={menuM}
            alt=""
            width={'30px'}
            height={'20px'}
          />
          <Menu type={'menu2'} show={menu} />
        </div>
      </>
    );
  }
}

export default Header;
