import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Input,
  Selector,
  ServiceBlock,
} from '../../Components/BasicComponents/BasicComponents';
import Header, { MobileHeader } from '../../Components/Header/Header';
import styles from './MobileServices.module.css';
import { TextArea } from '../../Components/BasicComponents/BasicComponents';
import Imgs from '../../Imgs/Imgs';
import DeleteConfirmation from '../../Pages/Services/Components/DeleteConfirmation/DeleteConfirmation';
import nodata from '../../Imgs/nodata.png';
import {
  Category,
  ServiceTypeChanger,
} from '../../Pages/Services/Components/ServicesBlock/ServicesBlock';
import Toast from '../../Components/Toast/Toast';
import BottomMenu from '../../Components/BottomMenu/BottomMenu';
import { useServicesStore } from 'Pages/Services/ServicesStore';
import { useMobileServicesStore } from './MobileServicesStore';
import {
  GroupSchedule,
  SearchClient,
  SectionHandler,
} from 'Pages/Services/Components/ServiceEditBlock/ServiceEditBlock';
import AddContactForm from 'Pages/Clients/Components/AddContactForm/AddContactForm';
import { useTranslation } from 'react-i18next';

function MobileServices(props) {
  const { t } = useTranslation();
  let { page, setPage } = useMobileServicesStore();
  let {
    service,
    services,
    getSpecialistServices,
    selectService,
    categories,
    serviceInputHandler,
    deleteCategoryInput,
    addCategoryInput,
    saveCategoriesButton,
    updateSpecialistCategories,
    showAddServiceWindow,
    getSpecialistCategories,
    addService,
    updateService,
    categoryInputHandler,
    isFirstViewServices,
    serviceType,
    groupService,
    groupServices,
    getSpecialistGroupServices,
    selectGroupService,
    groupServiceInputHandler,
    addGroupService,
    updateGroupService,
    sectionOfgroupWindow,
    showDeleteConfirmationWindow,
    showAddGroupServiceWindow,
  } = useServicesStore();

    useEffect(() => {
      async function af() {
        await isFirstViewServices(); 
        await getSpecialistServices();
        await getSpecialistCategories();
        await getSpecialistGroupServices();
      }
      af();
    }, []);


  let header = <MobileHeader />;

  let zaglushka = (
    <div className={styles.img_container}>
      <img src={nodata} width={'220px'} alt="" />
      <div className={styles.nodata_title}>{t('no_services')}</div>
    </div>
  );

  document.body.style.backgroundColor = 'white';

  //! Рендеринг

  if (page == 'services') {
    return (
      <>
        {window.scrollTo(0, 0)}
        {header}
        <div className={styles.container}>
          <div className={styles.title}>
            {t('services')}
            <span
              style={{ color: '#D0D5D9' }}
              onClick={() => {
                setPage('categories');
              }}
            >
              &nbsp;&nbsp;{t('categories')}
            </span>
          </div>

          <div style={{ marginTop: '15px' }}></div>
          <ServiceTypeChanger />

          {serviceType == 'Індивідуальні' ? (
            <>
              <div className={styles.services_container}>
                {services.length == 0 ? zaglushka : <></>}
                {services.map((el) => {
                  return (
                    <ServiceBlock
                      onClick={() => {
                        selectService(el._id);
                      }}
                      id={el._id}
                      name={el.name}
                      duration={`${el.hours} ${t('hours')} ${el.minutes} ${t('minutes')}`}
                      price={el.price}
                    />
                  );
                })}

                <div className={styles.emty_block}></div>
              </div>

              <div className={styles.button_container}>
                <Button
                  name={t('add_service')}
                  onClick={showAddServiceWindow}
                />
                <Button
                  onClick={() => {
                    window.location.href = '/profile/specialist';
                  }}
                  name={t('back')}
                  style={{
                    backgroundColor: '#ffff',
                    color: '#D0D5D9',
                    height: '50px',
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className={styles.services_container}>
                {groupServices.length == 0 ? zaglushka : <></>}
                {groupServices.map((el) => {
                  return (
                    <ServiceBlock
                      onClick={() => {
                        selectGroupService(el._id);
                      }}
                      id={el._id}
                      name={el.name}
                      hasGroup={true}
                      duration={`${el.hours} ${t('hours')} ${el.minutes} ${t('minutes')}`}
                      price={el.price}
                      style={
                        groupService._id == el._id
                          ? { backgroundColor: '#FABF7D66' }
                          : {}
                      }
                    />
                  );
                })}
              </div>

              <div className={styles.button_container}>
                <Button
                  name={t('add_group_service')}
                  onClick={() => {
                    showAddGroupServiceWindow();
                  }}
                />
                <Button
                  onClick={() => {
                    window.location.href = '/profile/specialist';
                  }}
                  name={t('back')}
                  style={{
                    backgroundColor: '#ffff',
                    color: '#D0D5D9',
                    height: '50px',
                  }}
                />
              </div>
            </>
          )}
        </div>
        <BottomMenu />
      </>
    );
  }

  if (page == 'categories') {
    return (
      <>
        {header}
        <div className={styles.container}>
          <div
            onClick={() => {
              setPage('services');
            }}
            style={{ color: '#D0D5D9' }}
            className={styles.title}
          >
            {t('services')}{' '}
            <span style={{ color: 'black' }}>&nbsp;&nbsp;{t('categories')}</span>
          </div>
          <div className={styles.services_container}>
            {categories.map((value, i) => {
              return (
                <Category
                  value={value}
                  onChange={(e) => {
                    categoryInputHandler(e, i);
                  }}
                  onClick_delete={() => {
                    deleteCategoryInput(i);
                  }}
                />
              );
            })}

            <div
              style={{ color: 'coral', marginTop: '5px' }}
              onClick={addCategoryInput}
            >
              {' '}
              + {t('add_category')}
            </div>

            <div className={styles.emty_block}></div>
          </div>
          <div className={styles.button_container}>
            {saveCategoriesButton ? (
              <Button
                name={t('save_changes')}
                onClick={updateSpecialistCategories}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  }

  if (page == 'edit') {
    return (
      <>
        {header}

        <div className={styles.container}>
          <div className={styles.title}>
            {service?.name}{' '}
            <span className={styles.delete}>
              <Imgs
                onClick={() => {
                  showDeleteConfirmationWindow('service');
                }}
                img={'delete'}
              />
            </span>
          </div>

          <div className={styles.inputs_container}>
            <div className={styles.name_label}>{t('category')}</div>
            <Selector
              style={{ color: 'coral', paddingLeft: '10px' }}
              id={'category'}
              value={service?.category}
              options={[t('no_category'), ...categories]}
              onChange={serviceInputHandler}
            />

            <div className={styles.name_label}>{t('service_name')} </div>
            <Input
              placeholder={t('service_name')}
              id={'name'}
              onChange={serviceInputHandler}
              value={service?.name}
            />

            <div className={styles.price_label}>{t('new_service')}</div> 
            <Input
              style={{ width: '260px' }}
              placeholder={t('cost')}
              id={'price'}
              onChange={serviceInputHandler}
              type={'withQ'}
              value={service?.price}
            />

            <div className={styles.duration_label}>{t('duration')}</div>
            <div className={styles.duration_container}>
              <Selector
                value={service.hours}
                id={'hours'}
                onChange={serviceInputHandler}
                style={{ width: '120px' }}
                options={[0, 1, 2, 3, 4, 5, 6]}
                label={t('hours')}
              />
              <Selector
                value={service.minutes}
                id={'minutes'}
                onChange={serviceInputHandler}
                style={{ width: '120px' }}
                options={[0, 15, 30, 45]}
                label={t('minutes')}
              />
            </div>

            <div className={styles.description_label}>{t('service_description')}</div>
            <TextArea
              onFocus={() => {
                window.ReactNativeWebView.postMessage('textInputOnFocus');
              }}
              placeholder={t('service_description')}
              id={'description'}
              onChange={serviceInputHandler}
              value={service?.description}
            />
          </div>

          <div style={{ height: '120px' }}></div>

          <div className={styles.button_container}>
            <Button
              name={t('save')}
              onClick={() => {
                updateService();
              }}
            />
            <Button
              onClick={() => {
                setPage('services');
              }}
              name={t('back')}
              style={{
                backgroundColor: '#ffff',
                color: 'grey',
                height: '50px',
              }}
            />
          </div>
        </div>

        <DeleteConfirmation />
      </>
    );
  }

  if (page == 'addNew') {
    return (
      <>
        {header}

        <div className={styles.container}>
          <div className={styles.title}>{t('new_service')}</div> 

          <div className={styles.inputs_container}>
            <div className={styles.name_label}>{t('category')}</div>
            <Selector
              style={{ color: 'coral', paddingLeft: '10px' }}
              id={'category'}
              value={service?.category}
              options={[t('no_category'), ...categories]}
              onChange={serviceInputHandler}
            />

            <div className={styles.name_label}>{t('service_name')}</div>
            <Input
              placeholder={t('service_name')}
              id={'name'}
              onChange={serviceInputHandler}
              value={service?.name}
            />

            <div className={styles.price_label}>{t('cost')}</div>
            <Input
              style={{ width: '260px' }}
              placeholder={t('cost')}
              id={'price'}
              onChange={serviceInputHandler}
              type={'withQ'}
              value={service?.price}
            /> 

            <div className={styles.duration_label}>{t('duration')}</div>
            <div className={styles.duration_container}>
              <Selector
                value={service?.hours}
                id={'hours'}
                onChange={serviceInputHandler}
                style={{ width: '120px' }}
                options={[0, 1, 2, 3, 4, 5, 6]}
                label={t('hours')}
              />
              <Selector
                value={service?.minutes}
                id={'minutes'}
                onChange={serviceInputHandler}
                style={{ width: '120px' }}
                options={[0, 15, 30, 45]}
                label={t('minutes')}
              />
            </div>

            <div className={styles.description_label}>{t('service_description')}</div>
            <TextArea
              onFocus={() => {
                window.ReactNativeWebView.postMessage('textInputOnFocus');
              }}
              placeholder={t('service_description')}
              id={'description'}
              onChange={serviceInputHandler}
              value={service?.description}
            />
            <div style={{ height: '140px' }}></div>
          </div>

          <div className={styles.button_container}>
            <Button name={t('add_service')} onClick={addService} />
            <Button
              onClick={() => {
                setPage('services');
              }}
              name={t('back')}
              style={{
                backgroundColor: '#ffff',
                color: 'grey',
                height: '50px',
              }}
            />
          </div>
        </div>
      </>
    );
  }

  if (page == 'addGroupService') {
    return (
      <>
        <AddContactForm />
        {header}

        <div className={styles.container}>
          <div className={styles.title}>{t('new_service')}</div>
          <div style={{ height: '15px' }}></div>
          <SectionHandler />

          {sectionOfgroupWindow == 'інформація' && (
            <>
              <div className={styles.inputs_container}>
                <div className={styles.name_label}>{t('service_name_placeholder')}</div>
                <Input
                  id={'name'}
                  onChange={groupServiceInputHandler}
                  value={groupService.name}
                />

                <div className={styles.price_label}>{t('cost')}</div>
                <Input
                  id={'price'}
                  onChange={groupServiceInputHandler}
                  type={'withQ'}
                  value={groupService.price}
                />

                <div className={styles.duration_label}>
                  {t('duration')}
                </div>
                <div className={styles.duration}>
                  <Selector
                    value={groupService.hours}
                    id={'hours'}
                    onChange={groupServiceInputHandler}
                    style={{ width: '120px' }}
                    options={[0, 1, 2, 3, 4, 5, 6]}
                    label={t('hours')}
                  />
                  <Selector
                    value={groupService.minutes}
                    id={'minutes'}
                    onChange={groupServiceInputHandler}
                    style={{ width: '120px' }}
                    options={[0, 15, 30, 45]}
                    label={t('minutes')}
                  />
                </div>

                <div className={styles.name_label}>{t('participants')}</div>
                <Input
                  id={'maxNumberOfParticipants'}
                  onChange={groupServiceInputHandler}
                  value={groupService.maxNumberOfParticipants}
                  style={{ width: '100px' }}
                  type={'number'}
                />

                <div className={styles.description_label}>{t('description')}</div>
                <TextArea
                  id={'description'}
                  onChange={groupServiceInputHandler}
                  value={groupService.description}
                />
              </div>
            </>
          )}

          {sectionOfgroupWindow == 'розклад' && (
            <>
              <div style={{}} className={styles.inputs_container}>
                <div className={styles.title}>{t('group_schedule')}</div>

                <GroupSchedule />
              </div>
            </>
          )}

          {sectionOfgroupWindow == 'учасники' && (
            <>
              <div style={{}} className={styles.inputs_container}>
                <SearchClient />
              </div>
            </>
          )}

          <div style={{ height: '110px' }}></div>
        </div>

        <div className={styles.button_container}>
          <Button name={t('add_group_service')} onClick={addGroupService} />
          <Button
            onClick={() => {
              setPage('services');
            }}
            name={t('back')}
            style={{
              backgroundColor: '#ffff',
              color: 'grey',
              height: '50px',
            }}
          />
        </div>
      </>
    );
  }

  if (page == 'editGroupService') {
    return (
      <>
        <AddContactForm />

        {header}

        <div className={styles.container}>
          <div className={styles.title_container}>
            <div className={styles.title}>{t('edit_service')}</div>
            <Imgs
              img={'delete'}
              onClick={() => {
                showDeleteConfirmationWindow('groupService');
              }}
            />
          </div>
          <div style={{ height: '15px' }}></div>
          <SectionHandler />

          {sectionOfgroupWindow == 'інформація' && (
            <>
              <div className={styles.inputs_container}>
                <div className={styles.name_label}>{t('service_name_placeholder')}</div>
                <Input
                  id={'name'}
                  onChange={groupServiceInputHandler}
                  value={groupService.name}
                />

                <div className={styles.price_label}>{t('cost')}</div>
                <Input
                  id={'price'}
                  onChange={groupServiceInputHandler}
                  type={'withQ'}
                  value={groupService.price}
                />

                <div className={styles.duration_label}>
                  {t('duration')}
                </div>
                <div className={styles.duration}>
                  <Selector
                    value={groupService.hours}
                    id={'hours'}
                    onChange={groupServiceInputHandler}
                    style={{ width: '120px' }}
                    options={[0, 1, 2, 3, 4, 5, 6]}
                    label={t('hours')}
                  />
                  <Selector
                    value={groupService.minutes}
                    id={'minutes'}
                    onChange={groupServiceInputHandler}
                    style={{ width: '120px' }}
                    options={[0, 15, 30, 45]}
                    label={t('minutes')}
                  />
                </div>

                <div className={styles.name_label}>{t('participants')}</div>
                <Input
                  id={'maxNumberOfParticipants'}
                  onChange={groupServiceInputHandler}
                  value={groupService.maxNumberOfParticipants}
                  style={{ width: '100px' }}
                  type={'number'}
                />

                <div className={styles.description_label}>{t('description')}</div>
                <TextArea
                  id={'description'}
                  onChange={groupServiceInputHandler}
                  value={groupService.description}
                />
              </div>
            </>
          )}

          {sectionOfgroupWindow == 'розклад' && (
            <>
              <div style={{}} className={styles.inputs_container}>
                <div className={styles.title}>{t('group_schedule')}</div>

                <GroupSchedule />
              </div>
            </>
          )}

          {sectionOfgroupWindow == 'учасники' && (
            <>
              <div style={{}} className={styles.inputs_container}>
                <SearchClient />
              </div>
            </>
          )}

          <div style={{ height: '110px' }}></div>
        </div>

        <div className={styles.button_container}>
          <Button name={t('save_changes')} onClick={updateGroupService} />
          <Button
            onClick={() => {
              setPage('services');
            }}
            name={t('back')}
            style={{
              backgroundColor: '#ffff',
              color: 'grey',
              height: '50px',
            }}
          />
        </div>

        <DeleteConfirmation />
      </>
    );
  }
}

export default MobileServices;
