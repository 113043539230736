import { create } from 'zustand';
import axios from 'axios';
import { checkAccess } from '../../access';
import { useToast2 } from '../../Components/Toast2/ToastStore';
import { useServicesStore } from 'Pages/Services/ServicesStore';
import { useAppointmentsStore } from 'Pages/Calendar/Components/AppointmentsBlock/AppointmentsStore';
import { useSelectClient } from 'Pages/Calendar/Components/SelectClient/useSelectClient';
import i18n from 'i18n';

let { showToast } = useToast2.getState();

export let useClientsStore = create((set, get) => ({
  specialist: {},
  clients: [],
  filteredClients: [],
  client: {
    name: '',
    surname: '',
    phone: '',
    email: '',
    comment: '',
    specialistId: '',
  },
  message: { clientId: '', clientName: '', channel: 'sms', msg: '' },
  sms: { symbols: 0, numberOfsms: 0 },
  addContactForm: false,
  //AddContactForm source
  source: undefined,

  editContactForm: false,
  confirmationModal: false,
  sendMessageModal: false,
  searchValue: undefined,

  getSpecialistsClients: async () => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/clients/specialist/${checkAccess()}`,
    );

    set({ clients: data });
  },

  //Форма додавання контакту
  showAddContactForm: (source) => {
    set({ source: source });

    set({
      client: {
        name: '',
        surname: '',
        phone: '',
        email: '',
        comment: '',
        specialistId: '',
      },
    });
    set({ addContactForm: true });
  },

  hideAddContactForm: () => {
    set({ addContactForm: false });
  },

  //Форма редагування контакту
  showEditContactForm: async (clientId) => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/clients/${clientId}`,
    );

    set({ client: data });
    set({ editContactForm: true });
  },

  hideEditContactForm: () => {
    set({ editContactForm: false });
  },

  //Вікно підтвердження видалення контакту

  showConfirmationModal: () => {
    get().hideEditContactForm();
    set({ confirmationModal: true });
  },

  hideConfirmationModal: () => {
    set({ confirmationModal: false });
  },

  inputHandler: (e, type = '') => {
    if (type === 'phone') {
      let client = get().client;
      set({ client: { ...client, phone: e } });

      return;
    }

    let client = get().client;
    set({ client: { ...client, [e.target.id]: e.target.value } });
  },

  saveClient: async () => {
    let client = get().client;

    //Валідація
    if (client.name.length == 0) {
      showToast('e', i18n.t('enter_client_name'));
      return;
    }

    // Перевірка на унікальність номера телефону
    let existingClient = get().clients.find((c) => c.phone === client.phone);
    if (client.phone && existingClient) {
      showToast('e', i18n.t('phone_already_exists'));
      return;
    }

    client.specialistId = checkAccess();
    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/clients`,
      client,
      { withCredentials: true },
    );

    if (data) {
      get().getSpecialistsClients();
      get().hideAddContactForm();
      set({
        client: {
          name: '',
          surname: '',
          phone: '',
          email: '',
          comment: '',
          specialistId: '',
        },
      });

      showToast('s', i18n.t('client_added_successfully'));
    }

    //Якщо контакт додається через вікно групової послуги
    if (new URL(window.location.href).pathname == '/specialist/services') {
      useServicesStore.setState({
        persistentGroupMembers: [
          ...useServicesStore.getState().persistentGroupMembers,
          data,
        ],
      });
      useServicesStore.getState().getSpecialistClients();
    }

    if (get().source == 'addAppointment') {
      useSelectClient.getState().getSpecialistClients();
      useAppointmentsStore.setState({ addAppointmentWindow: true });
      useSelectClient.setState({
        clientSearchInputValue: { value: data.name, label: data.name },
      });
      useSelectClient.setState({
        client: { name: data.name, phone: data.phone },
      });
      set({ source: undefined });
      return;
    }

    if (get().source == 'editAppointment') {
      useSelectClient.getState().getSpecialistClients();
      useAppointmentsStore.setState({ editAppointmentWindow: true });
      useSelectClient.setState({
        clientSearchInputValue: { value: data.name, label: data.name },
      });

      console.log(useSelectClient.getState().clientSearchInputValue);

      useSelectClient.setState({
        client: { name: data.name, phone: data.phone },
      });
      set({ source: undefined });

      return;
    }

    //Якщо контакт додається через учасників запису на групову послугу
    if (new URL(window.location.href).pathname == '/calendar') {
      useAppointmentsStore.setState({
        groupMembers: [...useAppointmentsStore.getState().groupMembers, data],
      });
      await useAppointmentsStore.getState().getSpecialistAppointments();
      useAppointmentsStore.setState({ groupAppointmentWindow: true });
    }
  },

  updateClient: async () => {
    let { data } = await axios.put(
      `${process.env.REACT_APP_API}/api/clients/`,
      get().client,
    );

    if (data) {
      get().getSpecialistsClients();
      get().hideEditContactForm();
      set({
        client: {
          name: '',
          surname: '',
          phone: '',
          email: '',
          comment: '',
          specialistId: '',
        },
      });
      showToast('s', i18n.t('changes_saved'));
    }
  },

  deleteClient: async () => {
    let { data } = await axios.delete(
      `${process.env.REACT_APP_API}/api/clients/${get().client._id}`,
    );
    get().getSpecialistsClients();
    get().hideConfirmationModal();
    showToast('s', i18n.t('client_deleted_successfully'));
  },

  filterClients: (e) => {
    set({ searchValue: e.target.value });

    let filtered = get().clients.filter((client) => {
      return client.name.includes(e.target.value);
    });
    if (filtered.length == 0) {
      filtered = get().clients.filter((client) => {
        return client.phone.includes(e.target.value);
      });
    }

    set({ filteredClients: filtered });
  },

  showSendMessageModal: ({ clientName, clientId }) => {
    set({ sendMessageModal: true });
    set({
      message: {
        ...get().message,
        clientId: clientId,
        clientName: clientName,
      },
    });
  },

  hideSendMessageModal: () => {
    set({ sendMessageModal: false });
    set({ message: { clientId: '', clientName: '', channel: 'sms', msg: '' } });
    set({ sms: { symbols: 0, numberOfsms: 0 } });
  },

  msgModalInputHandler: (e) => {
    set({ message: { ...get().message, [e.target.id]: e.target.value } });

    let smsLength =
      get().message.msg.length + get().specialist.visibleName.length + 2;

    set({
      sms: {
        ...get().sms,
        symbols: smsLength,
        numberOfsms: Math.ceil(smsLength / 70),
      },
    });
  },

  sendMessageToClient: async () => {
    let message = get().message;

    if (message.msg.length == 0) {
      showToast('e', i18n.t('message_cannot_be_empty'));
      return;
    }

    message.specialistId = checkAccess();

    message.msg = `${message.msg} "${get().specialist.visibleName}"`;

    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/specialist/send_message`,
      message,
    );

    if (data == 'client does not have email') {
      showToast('e', i18n.t('add_client_email_for_notification'));
      return;
    }
    if (data == 'client does not have phone number') {
      showToast('e', i18n.t('client_phone_number_missing'));
      return;
    }

    if (data == 'noSMS') {
      showToast('e', i18n.t('insufficient_sms_balance'));
      return;
    }
    if (data) {
      get().hideSendMessageModal();
      showToast('s', i18n.t('message_sent_successfully'));
      return;
    }
  },

  getSpecialistInfo: async () => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
    );

    set({ specialist: data });
    set({ sms: { ...get().sms, symbols: data.visibleName?.length + 2 } });
  },
}));
