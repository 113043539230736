import { create } from 'zustand';
import moment from 'moment';

export let useWeekSelectorStore = create((set, get) => ({
  currentWeek: undefined,
  weeks: undefined,
  datesOfWeek: [],

  getCurrentWeek: () => {
    let currentWeekNumber = moment().week() - 1;

    if (currentWeekNumber == -1) {
      currentWeekNumber = 0;
    }

    let currentYear = new Date().getFullYear();

    let year1 = getWeeks(currentYear, currentYear + 1);

    let year2 = getWeeks(currentYear + 1, currentYear + 2);

    let weeks = year1.concat(year2);

    set({ weeks: weeks, currentWeek: weeks[currentWeekNumber - 1] });
  },

  setCurrentWeekByDate: (date) => {
    set({ currentWeek: get().weeks[moment(date).week() - 2] });
  },

  goToNextWeek: () => {
    let i = get().weeks.indexOf(get().currentWeek);
    if (i == get().weeks.length) {
      return;
    }
    set({ currentWeek: get().weeks[i + 1] });
    get().getDatesOfWeek();
  },

  goToPreviousWeek: () => {
    let i = get().weeks.indexOf(get().currentWeek);
    if (i == 0) {
      return;
    }
    set({ currentWeek: get().weeks[i - 1] });
    get().getDatesOfWeek();
  },

  getDatesOfWeek: () => {
    if (!get().currentWeek) {
      return;
    }
    let firstDay = getFirstDayOfTheWeek(get().currentWeek);
    let lastDay = getLastDayOfTheWeek(get().currentWeek);
    set({ datesOfWeek: getDatesBetween(firstDay, lastDay) });
  },
}));

//Допоміжні

//Отримати масив неділь
function getWeeks(year1, year2) {
  var weeks = [];
  var date1 = new Date(year1, 0, 1);
  var date2 = new Date(year2, 0, 1);

  // If only a single year is provided, set date2 to the end of that year
  if (!year2) {
    date2 = new Date(year1 + 1, 0, 0);
  }

  // Find the first Monday of the year
  while (date1.getDay() !== 1) {
    date1.setDate(date1.getDate() + 1);
  }

  // Loop through the weeks and add them to the array
  while (date1 < date2) {
    var weekStart = new Date(date1);
    var weekEnd = new Date(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate() + 6,
    );

    weeks.push(
      moment(weekStart).format('YYYY/MM/DD') +
      ' - ' +
      moment(weekEnd).format('YYYY/MM/DD'),
    );
    date1.setDate(date1.getDate() + 7);
  }

  return weeks;
}

//Отримати дату початку неділі
function getFirstDayOfTheWeek(range) {
  let parts = range.split('-');
  return parts[0];
}

//Отримати дату кінця неділі
function getLastDayOfTheWeek(range) {
  let parts = range.split('-');
  return parts[1];
}

// Отримати масив дат з проміжка дат
function getDatesBetween(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const dates = [];

  let currentDate = start;
  while (currentDate <= end) {
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // getMonth() returns a zero-based value, so we need to add 1
    const year = currentDate.getFullYear();
    dates.push(moment(`${year}/${month}/${day}`).format('YYYY/MM/DD'));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}
