import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import styles from './SheduleBlock.module.css';
import stylesM from './SheduleBlockM.module.css';
import Imgs from '../../Imgs/Imgs';
import { Button } from '../BasicComponents/BasicComponents';
import axios from 'axios';
import { checkAccess } from '../../access';
import Toast from '../../Components/Toast/Toast';
import Menu from '../../Components/Menu/Menu';
import { useDB } from 'utils/DB';
import { API } from 'utils/api';
import { useTranslation } from 'react-i18next';

function IntervalSelector(props) {
  let times = [
    '00:00',
    '00:15',
    '00:30',
    '00:45',
    '01:00',
    '01:15',
    '01:30',
    '01:45',
    '02:00',
    '02:15',
    '02:30',
    '02:45',
    '03:00',
    '03:15',
    '03:30',
    '03:45',
    '04:00',
    '04:15',
    '04:30',
    '04:45',
    '05:00',
    '05:15',
    '05:30',
    '05:45',
    '06:00',
    '06:15',
    '06:30',
    '06:45',
    '07:00',
    '07:15',
    '07:30',
    '07:45',
    '08:00',
    '08:15',
    '08:30',
    '08:45',
    '09:00',
    '09:15',
    '09:30',
    '09:45',
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45',
    '13:00',
    '13:15',
    '13:30',
    '13:45',
    '14:00',
    '14:15',
    '14:30',
    '14:45',
    '15:00',
    '15:15',
    '15:30',
    '15:45',
    '16:00',
    '16:15',
    '16:30',
    '16:45',
    '17:00',
    '17:15',
    '17:30',
    '17:45',
    '18:00',
    '18:15',
    '18:30',
    '18:45',
    '19:00',
    '19:15',
    '19:30',
    '19:45',
    '20:00',
    '20:15',
    '20:30',
    '20:45',
    '21:00',
    '21:15',
    '21:30',
    '21:45',
    '22:00',
    '22:15',
    '22:30',
    '22:45',
    '23:00',
    '23:15',
    '23:30',
    '23:45',
    '24:00',
  ];

  let options = times.map((el) => {
    return <option value={el}>{el}</option>;
  });

  return (
    <>
      <div className={styles.interval_selector_container}>
        <label className={styles.interval_selector_label}>{props.label}</label>
        <select
          onChange={props.onChange}
          value={props.value}
          className={styles.interval_selector}
        >
          {options}
        </select>
      </div>
    </>
  );
}

function Interval(props) {
  const { t } = useTranslation();
  
  return (
    <>
      <div className={styles.interval}>
        <IntervalSelector
          onChange={props.onChangeFrom}
          value={props.valueFrom}
          label={t('from')}
        />
        <IntervalSelector
          onChange={props.onChangeTo}
          value={props.valueTo}
          label={t('to')}
        />
        <div
          className={styles.delete_interval_container}
          onClick={props.onClick_delete}
        >
          <Imgs img={'deleteInterval'} />
        </div>
      </div>
    </>
  );
}

let DayBlock = (props) => {
  const { t } = useTranslation();
  let [state, setState] = useState('closed');
  let [inputs, setInputs] = useState([]);

  let intervals = inputs.map((el, index) => {
    return (
      <Interval
        onChangeFrom={(e) => fromHandler(e, index)}
        onChangeTo={(e) => toHandler(e, index)}
        onClick_delete={() => removeInput(index)}
        key={index}
        valueFrom={inputs[index]?.from}
        valueTo={inputs[index]?.to}
      />
    );
  });

  //Відкрити/Закрити інтервали
  function handler() {
    setInputs(props.intervals);
    state == 'closed' ? setState('open') : setState('closed');
  }

  //Додати інтервали
  function addInterval() {
    const newInputs = [...inputs, { from: '08:00', to: '17:00' }];
    setInputs(newInputs);
    props.onChange(newInputs, props.eday);
  }

  //Обработчик поля "С"
  function fromHandler(e, index) {
    let data = [...inputs];
    data[index].from = e.target.value;
    setInputs(data);
    props.onChange(data, props.eday);
  }

  //Обработчик поля "До"
  function toHandler(e, index) {
    let data = [...inputs];
    data[index].to = e.target.value;
    setInputs(data);
    props.onChange(data, props.eday);
  }

  //Удаление поля
  function removeInput(index) {
    let data = [...inputs];
    data.splice(index, 1);
    setInputs(data);
    props.onChange(data, props.eday);
  }

  useEffect(() => {
    if (true) {
      setState('closed');
    }
  }, [props.change]);

  //!Рендеринг

  if (state == 'closed') {
    return (
      <div onClick={handler} className={styles.day_container_closed}>
        <div className={styles.day_name_closed}>{props.day}</div>
        <div className={styles.arrow}>
          <Imgs img={'arrowRight'} />
        </div>
      </div>
    );
  }

  if (state == 'open') {
    return (
      <>
        <div className={styles.main_day_container}>
          <div onClick={handler} className={styles.day_container}>
            <div className={styles.day_name}>{props.day}</div>
            <div className={styles.arrow}>
              <Imgs img={'arrowDown'} />
            </div>
          </div>

          <div className={styles.intervals}>
            <div className={styles.slots_title}>{t('work_hours')}</div>
            {intervals}
            <div onClick={addInterval} className={styles.add_interval}>
              {t('add_interval')}
            </div>
          </div>
        </div>
      </>
    );
  }
};

function SheduleBlock(props) {
  const { t } = useTranslation();
  let specialistId = checkAccess();
  let { specialist } = useDB();
  let defaultInterval = { from: '08:00', to: '17:00' };

  let [shedule, setShedule] = useState({
    monday: [defaultInterval],
    tuesday: [defaultInterval],
    wednesday: [defaultInterval],
    thursday: [defaultInterval],
    friday: [defaultInterval],
    saturday: [],
    sunday: [],
  });

  let [toast, setToast] = useState({ type: '', show: false, msg: '' });
  let [dayBlockState, setDayBlockState] = useState('');

  //Показати тост
  function showToast(type, msg) {
    if (type == 'e') {
      type = 'error';
    }
    if (type == 's') {
      type = 'success';
    }
    setToast({ type, show: true, msg });
    setTimeout(() => {
      setToast({ type: '', show: false, msg: '' });
    }, 3000);
  }

  //Обновить стейт при изменениии данных в  дочерних компонентах
  function handleSheduleChange(intervals, day) {
    setShedule((ps) => ({ ...ps, [day]: intervals }));
  }

  //Оновити графік
  async function updateShedule() {
    setDayBlockState(Date.now());

    if (!isValidTimeSlots(shedule)) {
      showToast(
        'e',
        t('time_error'),
      );
      return;
    }
    if (!isValidTimeSlots2(shedule)) {
      showToast(
        'e',
        t('overlapping_intervals'),
      );
      return;
    }

    let data = await API.Specialist.updateSchedule(shedule);
    if (data) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      showToast('s', t('work_hours_updated'));
    }
  }

  useEffect(() => {
    async function af() {
      if (specialist.shedule) {
        setShedule(specialist.shedule);
      } else {
        setShedule({
          monday: [defaultInterval],
          tuesday: [defaultInterval],
          wednesday: [defaultInterval],
          thursday: [defaultInterval],
          friday: [defaultInterval],
          saturday: [],
          sunday: [],
        });
        updateShedule();
      }
    }
    af();
  }, [specialist]);

  //! Рендеринг

  if (props.platform == 'mobile') {
    return (
      <>
        <Toast show={toast.show} type={toast.type} msg={toast.msg} />

        <div className={stylesM.mobile_container}>
          <div className={stylesM.title}>{t('my_schedule')}</div>

          <div className={stylesM.days_container}>
            <DayBlock
              change={dayBlockState}
              day={t('monday')}
              eday={'monday'}
              intervals={shedule.monday}
              onChange={handleSheduleChange}
            />
            <DayBlock
              change={dayBlockState}
              day={t('tuesday')}
              eday={'tuesday'}
              intervals={shedule.tuesday}
              onChange={handleSheduleChange}
            />
            <DayBlock
              change={dayBlockState}
              day={t('wednesday')}
              eday={'wednesday'}
              intervals={shedule.wednesday}
              onChange={handleSheduleChange}
            />
            <DayBlock
              change={dayBlockState}
              day={t('thursday')}
              eday={'thursday'}
              intervals={shedule.thursday}
              onChange={handleSheduleChange}
            />
            <DayBlock
              change={dayBlockState}
              day={t('friday')}
              eday={'friday'}
              intervals={shedule.friday}
              onChange={handleSheduleChange}
            />
            <DayBlock
              change={dayBlockState}
              day={t('saturday')}
              eday={'saturday'}
              intervals={shedule.saturday}
              onChange={handleSheduleChange}
            />
            <DayBlock
              change={dayBlockState}
              day={t('sunday')}
              eday={'sunday'}
              intervals={shedule.sunday}
              onChange={handleSheduleChange}
            />
            <div className={stylesM.empty_block}></div>
          </div>
          <div className={stylesM.buttons_container}>
            <Button
              name={t('save')}
              style={{ marginTop: '15px' }}
              onClick={updateShedule}
            />
            <Button
              onClick={() => {
                props.goToPage('mainMenu');
              }}
              name={t('back')}
              style={{
                backgroundColor: '#ffff',
                color: 'grey',
                height: '50px',
              }}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Toast show={toast.show} type={toast.type} msg={toast.msg} />

      <div className={styles.container}>
        <div className={styles.title}>{t('my_schedule')}</div>

        <div className={styles.days_container}>
          <DayBlock
            change={dayBlockState}
            day={t('monday')}
            eday={'monday'}
            intervals={shedule.monday}
            onChange={handleSheduleChange}
          />
          <DayBlock
            change={dayBlockState}
            day={t('tuesday')}
            eday={'tuesday'}
            intervals={shedule.tuesday}
            onChange={handleSheduleChange}
          />
          <DayBlock
            change={dayBlockState}
            day={t('wednesday')}
            eday={'wednesday'}
            intervals={shedule.wednesday}
            onChange={handleSheduleChange}
          />
          <DayBlock
            change={dayBlockState}
            day={t('thursday')}
            eday={'thursday'}
            intervals={shedule.thursday}
            onChange={handleSheduleChange}
          />
          <DayBlock
            change={dayBlockState}
            day={t('friday')}
            eday={'friday'}
            intervals={shedule.friday}
            onChange={handleSheduleChange}
          />
          <DayBlock
            change={dayBlockState}
            day={t('saturday')}
            eday={'saturday'}
            intervals={shedule.saturday}
            onChange={handleSheduleChange}
          />
          <DayBlock
            change={dayBlockState}
            day={t('sunday')}
            eday={'sunday'}
            intervals={shedule.sunday}
            onChange={handleSheduleChange}
          />
        </div>

        <Button
          name={t('save')}
          style={{ marginTop: '15px' }}
          onClick={updateShedule}
        />
      </div>
    </>
  );
}

export default SheduleBlock;

//Допоміжні

function isValidTimeSlots(days) {
  for (const day in days) {
    const timeSlots = days[day];
    for (const timeSlot of timeSlots) {
      const from = new Date('1970-01-01 ' + timeSlot.from);
      const to = new Date('1970-01-01 ' + timeSlot.to);
      if (to <= from) {
        return false;
      }
    }
  }
  return true;
}

function isValidTimeSlots2(days) {
  for (const day in days) {
    const timeSlots = days[day];
    for (let i = 0; i < timeSlots.length; i++) {
      for (let j = i + 1; j < timeSlots.length; j++) {
        const from1 = new Date('1970-01-01 ' + timeSlots[i].from);
        const to1 = new Date('1970-01-01 ' + timeSlots[i].to);
        const from2 = new Date('1970-01-01 ' + timeSlots[j].from);
        const to2 = new Date('1970-01-01 ' + timeSlots[j].to);
        if ((from1 <= from2 && from2 < to1) || (from1 < to2 && to2 <= to1)) {
          return false;
        }
      }
    }
  }
  return true;
}
